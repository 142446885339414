import React from "react"
import { useTheme } from "emotion-theming"
import Layout from "../../components/Layout"
import "../../styles/global.css"
import { Section } from "../../components/PageComponents/Section"
import SEO from "../../components/seo"
import { ContentContainer } from "../../components/PageComponents/ContentContainer"

const OmEvoliPage = () => {
  const theme = useTheme()

  return (
    <Layout>
      <SEO title="evoli - Om evoli" />
      <Section bgColor={theme.colors.primaryBackground}>
        <ContentContainer>
          <h3>Om evoli</h3>
          <p>
            evoli är alternativet för dig som sällan eller aldrig drabbas av
            skador och därför vill betala en så låg premie som möjligt. Genom
            att ha högre självrisker än övriga branschen, en digital process med
            en hög grad av självbetjäning är vår målsättning att kunna erbjuda
            riktigt bra priser till vår målgrupp.
          </p>
          <p>
            Innehållet i försäkringen tål självklart att jämföras med de stora
            bolagen och skulle olyckan vara framme så är det för oss en
            självklarhet att du ska få en kompetent och bra skadereglering.
          </p>
          <p>
            Vi tror dessutom på valmöjligheter så hos oss drabbas du inte av
            någon bindningstid utan du har alltid rätt att byta försäkringsbolag
            när du vill.
          </p>
          <p>
            <i>
              evoli är ett företag med fokus på insurtech. Bland grundarna så
              finns både erfarna entreprenörer och människor med
              specialistkunskap inom försäkring. evoli har tillstånd av
              Finansinspektionen att förmedla försäkringar.
            </i>
          </p>
        </ContentContainer>
      </Section>
    </Layout>
  )
}

export default OmEvoliPage
